html{
  padding: 0;
  margin: 0;
}

body {
  padding: 0;
  margin: 0;
  background-color: #00586d;
}



#app{
  height:100vh;
  margin:0;
  padding: 0;
  max-height: 100vh;

  /*!* Internet Explorer 10 *!*/
  /*display:block;*/
  /*display:-ms-flexbox;*/
  /*-ms-flex-pack:center;*/
  /*-ms-flex-align:center;*/

  /*!* Firefox *!*/
  /*display:-moz-box;*/
  /*-moz-box-pack:center;*/
  /*-moz-box-align:center;*/

  /*!* Safari, Opera, and Chrome *!*/
  /*display:-webkit-box;*/
  /*-webkit-box-pack:center;*/
  /*-webkit-box-align:center;*/

  /* !*W3C*!*/
  /*display:box;*/
  /*box-pack:center;*/
  /*box-align:center;*/
}
