/*#partlyCloudyIcon #outer{*/
/*    scale:0,transformOrigin:"50% 50%", ease:Circ.easeOut*/
/*}*/

/*#partlyCloudyIcon #middle{*/
/*    scale:0,*/
/*    transformOrigin:"50% 50%";*/
/*    ease:Circ.easeOut*/
/*}*/

/*#partlyCloudyIcon #inner{*/
/*    scale:0,*/
/*    transformOrigin:"50% 50%";*/
/*    ease:Circ.easeOut*/
/*}*/

/*#partlyCloudyIcon #cloud{*/
/*    alpha:0*/
/*}*/

.weather_icon object{
    width: 80%!important;
}