.app {
  color: white;
}

.hidden {
  display: none;
}

p.data_indicators{
  font-size: 4vh!important;
  font-weight:bold;
}

/* PAGE ONE */
#container_1 .row{
  margin: 0;
}

/* AGRESSIVE RESPONSIVE */
/*@media screen and (min-height: 1000px){*/
/*  #container_1 .container_1_part_1{*/
/*    padding: 0 0 2% 0;*/
/*  }*/
/*  #container_1 .container_1_part_2{*/
/*    padding: 0 0 4% 0;*/
/*  }*/
/*}*/

#container_1 .container_1_part_1{
  padding: 0 0 2vh 0;
}

/* PAGE ONE CONTAINER ONE */
#container_1 .container_1_part_1 .title{
  margin: 0;
  padding: 0;
}

#container_1 .logo{
  width:8vw;
  margin-right: 3vw;
}

#container_1 .title{
  display: inline-flex;
}
#container_1 .title span{
  font-size: 10vh;
}

/* PAGE ONE CONTAINER TWO */
#container_1 .container_1_part_2{
  /*margin: 4% 0 4% 0;*/
  margin-top: 2vw;
  margin-bottom: 2vw;
}

#container_1 .weather_texts{
  /*line-height: 6vh;*/
  text-align: center;
  width: 25vw;
}
#container_1 .weather_temperature{
  font-size: 5vh;
}
#container_1 .weather_date{
  font-size:5vh;
}
#container_1 .weather_hour {
  font-size: 5vh;
  /*line-height: 8vh;*/
}

#container_1 .weather_icon img{
  /*width: 100%;*/
  height: 30vh;
}

#container_1 .schedule{
  font-size: 4vh;
  padding-right: 6vw;
}

/* PAGE ONE CONTAINER THREE */
#container_1 .news{
  font-size: 5vh;
  border-radius: 5vh 5vh;
  border: 1vh solid #60f8fc;
  box-shadow:  0 0 0.5vh black;
  width: 80vw;
  padding: 5vh;
  margin: auto;
}













/* PAGE TWO */

#container_2 .row{
  margin: 0;
  padding: 2vw;
}

/* Size of icon indicators */
#container_2 .big_icon{
  font-size: 30vh;
}
#container_2 .large_icon{
  font-size: 18vh;
}
#container_2 .normal_icon{
  font-size: 8vh;
}

/* Border indicators */
#container_2 .water .vague{
  color: #1CD5FD;
}

#container_2 .water{
  border: 1vh solid #1CD5FD;
  border-radius: 5vh;
  box-shadow:  0 0 2vw black;
  margin-right: 3vw;
}

#container_2 .changing_room{
  margin-bottom: 3vh;
  border: 1vh solid #4891E2;
  border-radius: 5vh;
  box-shadow:  0 0 2vw black;
}

#container_2 .hall_air .vent{
  color: #B8ECFE;
}

#container_2 .hall_air{
  border: 1vh solid #B8ECFE;
  border-radius: 5vh;
  box-shadow:  0 0 2vw black;
}

/* Design indicators */
#container_2 .title_indicators{
  font-size: 3vh;
  margin: 0;
}

#container_2 .data_indicators{
  font-size: 25px;
  /*margin: 0;*/
}

#container_2 .indicators_texts{
  margin: 1vh;
}

#container_2 .text_conso{
  font-size: 2vw!important;
  color: white;
}


/* Color indicators */
#container_2 .changing_room_color{
  color: #4891e2;
}

#container_2 .hall_air_color{
  color: #4891e2;
}

/* Energie custom */
#container_2 .C_energie_data{
  font-size: 4vh;
  margin: 0;
  font-weight: bold;
  line-height: 1;
}

#container_2 .C_energie_unit{
  font-size: 4vh;
  margin: 0;
  /*padding-left: 8vh;*/
}

/* Energie custom */
#container_2 .c_eau_data{
  font-size: 4vh;
  margin: 0;
  font-weight: bold;
  line-height: 1;
}

#container_2 .c_eau_unit{
  font-size: 4vh;
  margin: 0;
  /*padding-left: 8vh;*/
}

#container_2 .conso_eau .eau{
  color: #03d498;
}

#container_2 .conso_eau{
  border: 1vh solid #03d498;
  border-radius: 5vh;
  box-shadow:  0 0 2vw black;
  margin-right: 6vh;
}

#container_2 .conso_energie .energie{
  color: #ffa000;
}

#container_2 .conso_energie {
  border: 1vh solid #ffa000;
  border-radius: 5vh;
  box-shadow: 0 0 2vw black;
}

#container_2 .marg_bottom{
  margin-bottom: 6vh;
}

#container_2 .Comp_eau{
  font-size: 4vh;
}

#container_2 .Comp_energie{
  font-size: 4vh;
}

.loading:after{
  content: "";
  position: fixed;
  z-index: 10001;
  top: 50%;
  left: calc(50% - 30px);
  transform: translate(-50%,-50%);
  /*border: 8px solid #f3f3f3;*/
  border-radius: 50%;
  border-top: 8px solid white!important;
  width: 60px;
  height: 60px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { border: #4285F4 8px solid; transform:rotate(0deg)}
  25% { border: #DE3E35 8px solid; }
  50% { border: #F7C223 8px solid; }
  75% { border: #1B9A59 8px solid; }
  100% { border: #4285F4 8px solid; transform:rotate(360deg)}
}





/*.header {*/

/*}*/

/*@keyframes App-logo-spin {*/
/*  from {*/
/*    transform: rotate(0deg);*/
/*  }*/
/*  to {*/
/*    transform: rotate(360deg);*/
/*  }*/
/*}*/
